<template>
  <div>
    <div class="login-bg">
      <vue-particles
        color="#A1B0C5"
        :particleOpacity="0.9"
        :particlesNumber="50"
        shapeType="circle"
        :particleSize="12"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="2"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="false"
        clickMode="push"
      ></vue-particles>
    </div>
    <div class="login-container">
      <div class="login-header">
        <h1 class="login-main-title login-hide">Cold Cloud</h1>
        <h2 class="login-sub-title">冷云 — <span style="font-weight: 400;">验证</span>数据管理专家</h2>
      </div>
      <el-form
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        label-position="top"
        label-width="0px"
      >
        <el-form-item prop="account">
          <el-input
            type="tel"
            v-model.number="loginForm.account"
            auto-complete="off"
            placeholder="手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" :style="{ display:smsLoginSwitch?'none':' block' }">
          <el-input
            type="password"
            v-model="loginForm.password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleSubmit('loginForm')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="smscode" :style="{ display:smsLoginSwitch?'block':'none' }">
          <el-input
            type="text"
            v-model="loginForm.smscode"
            auto-complete="off"
            placeholder="短信验证码"
            @keyup.enter.native="handleSubmit('loginForm')"
          >
            <timer-btn
              slot="append"
              ref="loginTimerBtn"
              :second="120"
              @run="sendSMSCode('loginForm')"
            ></timer-btn>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="login-button"
            @click.native.prevent="handleSubmit('loginForm')"
            :loading="logining"
          >登录</el-button>
        </el-form-item>
      </el-form>
      <el-button type="text" @click="switchLoginType">{{loginSwitchMsg[Number(smsLoginSwitch)]}}</el-button>
      <a class="login-admin-link" href="/admin">管理员登录切换</a>
    </div>
    <div class="login-footer-warp">
      <div class="login-footer">
        <a target="_blank" href="http://beian.miit.gov.cn/">苏ICP备17057842号</a>
        <p>
          Copyright © 2010 - 2025 常州数点测控技术有限公司 版权所有
          <a target="_blank" href="/privacy">隐私政策</a>
        </p>
        <p>客服热线：4009281998</p>
      </div>
    </div>
  </div>
</template>
<script>
import NProgress from "nprogress";
import timerBtn from "../components/TimerButton";
import VueParticles from "../components/vue-particles";
import { setToken } from "@/utils/auth";
import { mapMutations } from "vuex";
export default {
  components: {
    timerBtn,
    VueParticles
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入11位手机号码"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else if (
          !/^(1[3-9]{1}[0-9]{9})$/.test(
            value
          )
        )
          callback(new Error("请输入正确的手机号码"));
        else {
          callback();
        }
      }, 300);
    };
    var validatePassword = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      } else {
        if (value.trim() === "") {
          return callback(new Error("请不要只输入空格"));
        }
        callback();
      }
    };
    return {
      logining: false,
      smsLoginSwitch: false,
      loginSwitchMsg: ["短信验证码登录", "帐号密码登录"],
      loginForm: {
        account: "",
        password: "",
        smscode: ""
      },
      loginRules: {
        account: [
          {
            validator: validatePhone,
            trigger: "blur"
          }
        ],
        password: [
          {
            validator: validatePassword,
            trigger: "blur"
          }
        ],
        smscode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          },
          {
            len: 6,
            message: "请输入6位验证码",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    ...mapMutations(["SET_TOKEN"]),
    switchLoginType() {
      this.smsLoginSwitch = !this.smsLoginSwitch;
      this.loginForm.smscode = "";
      this.loginForm.password = "";
    },

    handleSubmit(formName) {
      if (this.smsLoginSwitch) {
        this.loginForm.password = this.loginForm.smscode;
      } else {
        this.loginForm.smscode = "abcdef";
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.logining = true;
          NProgress.start();
          let loginParams = {
            mobilephone: this.loginForm.account.toString(),
            password: this.loginForm.password,
            smscode: this.loginForm.smscode
          };
          let url = this.smsLoginSwitch ? "/user/smslogin" : "/user/login";
          this.axios
            .post(url, loginParams)
            .then(response => {
              this.logining = false;
              NProgress.done();
              let { code, values, message } = response.data;
              if (code != 0) {
                this.$message({
                  message: `${message}`,
                  type: "warning"
                });
              } else {
                let { token } = values;
                setToken(token);
                this.SET_TOKEN(token);
                this.$router.push({
                  path: "/notice"
                });
              }
            })
            .catch(err => {
              this.logining = false;
              this.$message({
                message: "服务器通信故障，请检查网络连接是否正常",
                type: "warning"
              });
            });
        } else {
          return false;
        }
      });
    },
    sendSMSCode(formName) {
      this.$refs[formName].validateField("account", errMsg => {
        if (!errMsg) {
          this.$refs.loginTimerBtn.start();
          let params = {
            mobilephone: this.loginForm.account + ""
          };
          this.axios.post("/user/smssend", params).then(response => {
            this.logining = false;
            NProgress.done();
            if (response.data.code == 0) {
              this.$message({
                message: `${response.data.message}`,
                type: "success"
              });
            } else {
              this.$message({
                message: `${response.data.message}`,
                type: "warning"
              });
            }
          });
        }
      });
    }
  },
  mounted() {
    if (new Date() - new Date("2023-08-01 12:00") < 0) {
      this.$notify({
        title: "更新",
        position: "bottom-right",
        message: `<div style="color:gray;float:left;text-align:center;">2023-07-15</div><br>开放到期设备的历史数据查询，欢迎使用！`,
        duration: 10000,
        showClose: false,
        dangerouslyUseHTMLString: true
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.login-footer-warp {
  position: fixed;
  width: 100%;
  top: 100%;
  background: #eaeaea;
  text-align: center;
  z-index: 2;
  margin: 0;
  line-height: 22px;
  color: #ccc;
  .login-footer {
    position: relative;
    top: -84px;
    font-size: 12px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    a {
      color: #ccc;
      text-decoration: none;
    }
    a:hover {
      color: red;
      cursor: hand;
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
}

.login-bg {
  width: 100%;
  height: 100%;
  background-color: #f7fafc;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.login-container {
  width: 300px;
  height: 440px;
  vertical-align: middle;
  white-space: normal;
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -220px;

  .login-header {
    text-align: center;
    .login-hide {
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0;
    }
    .login-main-title {
      width: 85px;
      height: 85px;
      margin: 0 auto;
      background: url("../assets/coldcloud.png") no-repeat;
      background-size: contain;
    }
    .login-sub-title {
      margin: 10px 0 20px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1;
    }
  }
  .login-button {
    width: 100%;
    background: #0f88eb;
    box-shadow: none;
    border: 0;
    border-radius: 3px;
    line-height: 21px;
    color: #fff;
    display: block;
    font-size: 15px;
    cursor: pointer;
    font-family: "Microsoft Yahei";
  }
  .login-admin-link {
    float: right;
    margin: 5px;
    text-decoration: none;
  }
}
</style>