<template>
    <section>
        <el-dialog :visible.sync="visible" title="传感器数据偏差公式">
            <el-form label-width="160px" size="mini" label-suffix="：">
                <el-form-item label="设备名称">
                    {{ name }}
                </el-form-item>
                <el-form-item label="偏差公式说明">
                    <span>y=ax+b(F1~F2)</span>
                    <span class="note">&nbsp;注：(x:输入；y:校准值；a、b：公式参数；F1、F2：区间值)</span>
                </el-form-item>
                <!-- <el-form-item label="辅助值a、b计算器">
                    <el-button type="primary" @click='modalShow'>获取a,b</el-button>
                </el-form-item> -->
            </el-form>
            <FormulaRow ref="temperatureRowRef" @modalShow="modalShow" :label="'温度'" :formulas="tempList"
                :key="tempKey"></FormulaRow>
            <FormulaRow v-if="sensorkind == 0" ref="humidityRowRef" @modalShow="modalShow" :label="'湿度'"
                :formulas="humiList" :key="humiKey">
            </FormulaRow>
            <template slot="footer">
                <el-button key="cancel" @click="onCloses">取消</el-button>
                <el-button @click="handleSubmit" type="primary" :loading="confirmLoading">保存</el-button>
            </template>
        </el-dialog>
        <calculate-modal ref="calculateModal" @updateSource="updateSource"></calculate-modal>
    </section>
</template>
<script>
import calculateModal from "./calculate.vue";
import FormulaRow from "./formulaRow.vue";
export default {
    props: {
    },
    components: { calculateModal, FormulaRow },
    data() {
        return {
            name: "",
            sensorid: null,
            visible: false,
            sensorkind: 0,
            tempList: [],
            humiList: [],
            tempKey: this.randomString(10),
            humiKey: this.randomString(10),
            confirmLoading: false,
        }
    },
    methods: {
        randomString(len = 6) {
            return Math.random().toString(36).substring(2, 2 + len).padEnd(len, '0')
        },
        show(sensor) {
            const { devicename, sensorid, sensorcode, sensorkind, formula: { temperature, humidity } } = sensor
            this.name = `${devicename} ${sensorcode}#`
            this.sensorid = sensorid;
            // 使用不同的key使组件强制刷新渲染最新数据
            this.tempKey = this.randomString(10)
            this.humiKey = this.randomString(10)
            this.sensorkind = sensorkind;
            this.tempList = temperature || []
            this.humiList = humidity || []
            this.visible = true
        },
        modalShow({ label, index }) {
            console.log(label, index)
            this.$refs['calculateModal'].show(label, index)
        },
        updateSource(data) {
            const { a, b, label, index } = data
            if (label == "温度") {
                this.$refs['temperatureRowRef'].updateByIndex(index, a, b)
            }
            if (label == "湿度") {
                this.$refs['humidityRowRef'].updateByIndex(index, a, b)
            }
        },
        onCloses() {
            this.visible = false
        },
        hasNoOverlaps(ranges) {
            ranges.sort((a, b) => {
                if (a.f1 === b.f1) {
                    return a.f2 - b.f2;
                }
                return a.f1 - b.f1;
            });

            let lastEnd = -Infinity; // 初始化上一个区间的结束点为负无穷大  

            for (let range of ranges) {
                if (range.f1 <= lastEnd) {
                    return false;
                }
                lastEnd = range.f2; // 更新上一个区间的结束点为当前区间的结束点  
            }
            return true;
        },
        handleSubmit() {
            this.confirmLoading = true;
            let { pass, formulas: temperature } = this.$refs['temperatureRowRef'].getData();
            if (!pass) {
                this.confirmLoading = false;
                return
            } else {
                if (!this.hasNoOverlaps(temperature)) {
                    this.$message({
                        message: `请检查温度公式的f1, f2范围是否有重叠`,
                        type: 'warning',
                    })
                    this.confirmLoading = false;
                    return
                }
            }
            const para = { sensorid: this.sensorid, formula: { temperature } }

            if (this.sensorkind == 0) {
                let { pass, formulas: humidity } = this.$refs['humidityRowRef'].getData();
                if (!pass) {
                    this.confirmLoading = false;
                    return
                } else {
                    if (!this.hasNoOverlaps(humidity)) {
                        this.$message({
                            message: `请检查湿度公式的f1, f2范围是否有重叠`,
                            type: 'warning',
                        })
                        this.confirmLoading = false;
                        return
                    }
                }
                para.formula.humidity = humidity
            }
            this.axios.post("/cloudmanagement/updatesensorextend", para).then(reply => {
                this.confirmLoading = false;
                this.visible = false;
                this.$notify({
                    offset: 45,
                    title: "成功",
                    message: "保存成功",
                    type: "success"
                });
                this.$emit('reload')
            }).catch(e => {
                this.$message({
                    message: `保存失败，请联系管理员。`,
                    type: 'warning',
                })
            });

        }
    }
}</script>